import { useContext, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  TextField,
  Typography,
  Zoom,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { getUserDetails, getUserPermissions } from 'store/slices/userSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ROUTE } from 'route/RouteEnums';
import { loginSchema } from 'utils/schema';
import { AuthContext } from 'utils/AuthContext';
import { signIn } from 'store/slices/signInSlice';
import { permissions } from 'utils/constants';

export default function SignIn() {
  const [apiResponse, setApiResponse] = useState<any>({ error: undefined });
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const storeResponse: any = useAppSelector((state: any) => state.user);
  const { loginToMindWeal, setUserDetails, logoutFromMindWeal } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
    mode: 'all',
  });

  const getLogInParams = (data: any) => {
    return {
      email: data.email,
      password: data.password,
    };
  };

  const onSubmitForm = async (formData: any) => {
    setIsLoading(true);
    const params = getLogInParams(formData);
    await dispatch(signIn(params))
      .unwrap()
      .then((response: any) => {
        setIsLoading(false);
        if (response?.status === 200) {
          loginToMindWeal(response?.data?.token, response?.data?.refreshToken);
          //  dipatch getuserdetails service
          if (response?.data?.status === 'ACTIVE') {
            sessionStorage.setItem('firstimelogin', 'yes');
          }
          getPermissions(response?.data?.userId);          
          
        }
      })
      .catch((err: any) => {
        if (err?.err?.errorCode === 2000 || err?.err?.errorCode === 1000) {
          setApiResponse({
            //eslint-disable-next-line
            error:
              // eslint-disable-next-line max-len
              'Invalid credentials, please enter the right credentials. In case you have not registered, please sign up',
          });
        } else if (err?.err?.errorCode === 2001) {
          navigate(ROUTE.EMAILVERIFICATION, { state: { email: params.email } });
        }
        setIsLoading(false);
      });
  };

  const getPermissions = (userId: any) => {
    dispatch(getUserPermissions({}))
      .unwrap()
      .then((resp: any) => {
        if (resp?.status === 200) {
          if (!resp?.data?.permissions?.includes(permissions.CREATE_PATIENT)) {
            dispatch(getUserDetails(userId))
              .unwrap()
              .then((response: any) => {
                if (response?.status === 200) {
                  const { email, firstName, lastName, mobileNumber, userId, userStatus } = response.data.user;
                  const user = {
                    firstName,
                    lastName,
                    email,
                    mobileNumber,
                    userId,
                    userStatus,
                    childBirthDate: response?.data?.registrationDetails?.childBirthDate,
                    relation: response?.data?.registrationDetails?.relationship,
                    patientId: response?.data?.profileSummery?.patientId,
                    insurance: response?.data?.registrationDetails?.insurance,
                    permissions: resp?.data?.permissions.length ? resp?.data?.permissions : [],
                    isAdmin: false,
                    secureMessageUserId:resp?.data?.secureMessageUserId,
                    zipcode: response?.data?.registrationDetails?.zipCode,
                    isExistingUser:resp?.data?.isExistingUser
                  };
                  setUserDetails(user);
                  navigate(ROUTE.DASHBOARD);
                } else if (response?.data?.errorCode === 2000) {
                  setApiResponse({ error: 'invalid details' });
                } else {
                  logoutFromMindWeal();
                }
              });
          } else {     
            const user = {
              userId,
              isAdmin: true,
              ...resp?.data,
            };

            setUserDetails(user);
            navigate(ROUTE.ADMIN_DASHBOARD);
          }
        }
      });
  };

  return (
    <>
      <Box
        component='form'
        onSubmit={handleSubmit((formData) => onSubmitForm(formData))}
        sx={{ mr: { md: 8, lg: 18 }, ml: { md: 3, lg: 6 } }}
        onChange={() => setApiResponse({ error: null })}
      >
        <Typography variant='h1'>Sign in</Typography>
        <Typography variant='subtitle1' gutterBottom>
          Welcome back! Please enter your details.
        </Typography>
        {apiResponse?.error && (
          <Zoom in={true}>
            <Alert severity='error'>{apiResponse.error}</Alert>
          </Zoom>
        )}

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <InputLabel htmlFor='email'>Email</InputLabel>
            <TextField
              fullWidth
              id='email'
              placeholder='Enter your Email'
              autoComplete='email'
              // autoFocus
              {...register('email')}
              error={!!errors.email}
              helperText={errors?.email?.message}
              disabled={isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor='password'>Password</InputLabel>
            <TextField
              fullWidth
              type={showPassword ? 'text' : 'password'}
              id='password'
              placeholder='••••••••'
              autoComplete='current-password'
              {...register('password')}
              error={!!errors.password}
              helperText={errors.password ? errors.password?.message : ''}
              disabled={isLoading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton aria-label='toggle password visibility' onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
            <Typography component='span'>
              <Link href={ROUTE.FORGOTPASSWORD}>Forgot password</Link>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={storeResponse?.loaders?.isUserDetailsFetched ? true : false}
              variant='contained'
              // loadingPosition='start'
              type='submit'
              fullWidth
              sx={{ mt: 2 }}
            >
              Sign in
            </LoadingButton>
          </Grid>
          <Grid item xs={12}>
            <Button type='submit' fullWidth variant='outlined' sx={{ mb: 4 }} href={ROUTE.HOME} disabled={isLoading}>
              Back to Website
            </Button>
          </Grid>
          <Grid container justifyContent='center'>
            <Grid item>
              <Typography component='span' variant='body1'>
                Don&apos;t have an account?
                <Link href={ROUTE.SIGNUP}> Sign Up</Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
