import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { Box, Button, Container, Grid, InputLabel, TextField, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useAppSelector } from 'store/hooks';
import { NO, YES, permissions } from 'utils/constants';
import { emargencyGuardianSchema } from 'utils/schema';
import ReactPhoneInput from 'react-phone-input-material-ui';
import { useAuth } from 'utils/AuthContext';
import { Restricted } from 'components/Restricted';

// return Emergency GaurdianInformation component
const EmergencyGaudian = (props: any) => {
  //validation schema
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    setValue,
    watch,
    reset,
    clearErrors,
  } = useForm({
    resolver: yupResolver(emargencyGuardianSchema),
    mode: 'onChange',
  });
  const guardians = useAppSelector((state) => state.user.guardians);
  const isProfileDetailsFetched = useAppSelector((state) => state.user.loaders.isProfileDetailsFetched);

  const setEmergencyGuardianDetails = () => {
    
    if(props?.prePopulateDetails && props?.prePopulateDetails.isEmergency){
      setValue('firstName', props?.prePopulateDetails?.firstName, { shouldValidate: false, shouldDirty: false });
      setValue('lastName', props?.prePopulateDetails?.lastName, { shouldValidate: false, shouldDirty: false });
      setValue('email', props?.prePopulateDetails?.email, { shouldValidate: false, shouldDirty: false });
      setValue('relation', props?.prePopulateDetails?.relation, { shouldValidate: false, shouldDirty: false });
      setValue('mobileNumber', `1${props?.prePopulateDetails?.mobileNumber}`, 
        { shouldValidate: false, shouldDirty: false });
      setValue('isEmergency', YES, { shouldValidate: true, shouldDirty: false });
    }
    else if (props?.emergencyGaurdian) {
      Object.keys(props?.emergencyGaurdian).forEach((key: any) => {
        if (key === 'isEmergency' || key === 'isActiveCustody')
          setValue(key, props?.emergencyGaurdian[key] ? 'yes' : 'no', { shouldValidate: false, shouldDirty: false });
        else if (key === 'other' && props?.emergencyGaurdian['relation'] !== 'other')
          setValue(key, '', { shouldValidate: true, shouldDirty: false });
        else if (key === 'mobileNumber' && props?.emergencyGaurdian[key].length === 10)
          setValue(key, `1${props?.emergencyGaurdian[key]}`, { shouldValidate: false, shouldDirty: false });
        else setValue(key, props?.emergencyGaurdian[key], { shouldValidate: false, shouldDirty: false });
      });
    }      
    else {
      setValue('id', 0, { shouldValidate: true, shouldDirty: false });
      setValue('isEmergency', YES, { shouldValidate: true, shouldDirty: false });
      setValue('isActiveCustody', NO, { shouldValidate: true, shouldDirty: false });
      setValue('other', '', { shouldValidate: true, shouldDirty: false });
      clearErrors();
    }
  };

  useEffect(() => {
    setEmergencyGuardianDetails();
    //eslint-disable-next-line
  }, [guardians]);

  //submit EG form
  const onSubmitForm = (formData: any) => {
    function formatPhoneNumber(phoneNumberString:any) {
      const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      const match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        const intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
      }
      return null;
    }
    if(isValid && isDirty){
      const payload = guardians
        .filter((guardian: any) => guardian.guardianCategory !== formData.guardianCategory)
        .concat({
          ...formData,
          id: formData?.id ? formData.id : null,
          isEmergency: formData.isEmergency === 'yes' ? true : false,
          guardianCategory: 'emergency',
          isActiveCustody: false,
          other: formData.relation === 'other' ? formData?.other : '',
          mobileNumber: formData?.mobileNumber ? formatPhoneNumber(formData?.mobileNumber.slice(1)) : '',
        });
      props.onUpdateGuardian(payload, () => resetData(formData));
    }else{
      toast('No changes to update.',{
        type:'info',
        icon:true
      });
    }
  };

  const resetData = (formData: any) => {
    const message1 = 'Emergency guardian updated successfully.';
    const message2 = 'Emergency guardian updated successfully. You can upload your picture.';
    toast(props.pictureAvailable ? message1 : message2, {
      type: 'success',
      icon: true,
    });
    reset(formData);
  };

  const handleSkipStep = () =>{
    props.skipStep();
  };

  //return Emergency GaurdianInformation component
  return (
    <>
      <Container component='main' maxWidth='sm' sx={{ padding: '0px !important' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              marginBottom: 2,
            }}
          >
            <Typography variant='body1' gutterBottom sx={{ backgroundColor: '#FFFBF1', padding: '8px 8px 4px 8px' }}>
              <b>IMPORTANT:</b> This person will be contacted in case of emergencies if Primary and Secondary 
              Guardian do not respond.
            </Typography>
          </Box>
          
          <Box sx={{ mt: 1 }} component='form' noValidate onSubmit={handleSubmit((formData) => onSubmitForm(formData))}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <InputLabel htmlFor='EGfirstName'>{props?.from !== 'admin' ? 'First Name*' : 'First Name'}</InputLabel>
                <TextField
                  id='EGfirstName'
                  autoComplete='firstName'
                  placeholder="Enter contact’s First Name"
                  required
                  fullWidth
                  autoFocus
                  {...register('firstName')}
                  error={!!errors.firstName}
                  helperText={errors.firstName ? errors.firstName?.message : ''}
                  disabled={props?.from === 'admin'}
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel htmlFor='lastName'>{props?.from !== 'admin' ? 'Last Name*' : 'Last Name'}</InputLabel>
                <TextField
                  id='EGlasttName'
                  autoComplete='lastName'
                  placeholder="Enter contact’s Last Name"
                  required
                  fullWidth
                  
                  {...register('lastName')}
                  error={!!errors.lastName}
                  helperText={errors.lastName ? errors.lastName?.message : ''}
                  disabled={props?.from === 'admin'}
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel htmlFor='relation'>
                  {props?.from !== 'admin' ? 'Relationship to the patient*' : 'Relationship to the patient'}
                </InputLabel>
                <TextField
                  id='relation'
                  autoComplete='relation'
                  placeholder='Enter relationship to the patient'
                  //select // tell TextField to render select
                  fullWidth
                  {...register('relation')}
                  error={!!errors.relation}
                  helperText={errors.relation ? errors.relation?.message : ''}
                  value={watch('relation') ? watch('relation') : ''}
                  disabled={props?.from === 'admin'}
                >
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel htmlFor='mobileNumber'>
                  {props?.from !== 'admin' ? 'Phone Number*' : 'Phone Number'}
                </InputLabel>
                <ReactPhoneInput
                  component={TextField}
                  label=''
                  placeholder='+1 (555) 000-0000'
                  onlyCountries={['us']}
                  country={'us'}
                  masks={{ us: '(...) ...-....' }}
                  autoFormat={true}
                  disableDropdown={true}
                  defaultErrorMessage={'Please enter a phone number'}
                  {...register('mobileNumber')}
                  inputProps={{
                    helperText: errors.mobileNumber ? errors.mobileNumber?.message : '',
                    error: !!errors.mobileNumber,
                  }}
                  countryCodeEditable={false}
                  onChange={(e:any) => {
                    setValue('mobileNumber', e, { shouldValidate: true, shouldDirty: true });
                  }}
                  value={watch('mobileNumber') ? watch('mobileNumber') : null}
                  disabled={props?.from === 'admin'}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel htmlFor='EGemailAddress'>
                  {'Email Address'}
                </InputLabel>
                <TextField
                  id='EGemailAddress'
                  autoComplete='EGemailAddress'
                  placeholder='Enter email address'
                  fullWidth
                  {...register('email')}
                  error={!!errors.email}
                  helperText={errors.email ? errors.email?.message : ''}
                  disabled={props?.from === 'admin'}
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </Grid>
            </Grid>

            <Restricted permission={permissions.EDIT_PROFILE}>
              <Grid container justifyContent='flex-end'>
                <Grid item> 
                  <Button size='small' variant='text' sx={{ mt: 2, mb: 2 }}
                    onClick={()=>handleSkipStep()}> 
                  Skip This Step </Button> 
                </Grid>
                <Grid item display='flex'>
                  <Button
                    variant='text'
                    sx={{ mt: 2, mb: 2, mr: 2 }}
                    onClick={props?.handleDeleteGuardian}
                    disabled={!props?.emergencyGaurdian}
                  >
                    Delete
                  </Button>
                  <LoadingButton
                    loading={isProfileDetailsFetched}
                    variant='contained'
                    type='submit'
                    fullWidth
                    sx={{ mt: 2, mb: 2 }}
                  >
                    Save
                  </LoadingButton>
                </Grid>
              </Grid>
            </Restricted>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default EmergencyGaudian;
