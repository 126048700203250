import React, {useEffect, useState } from 'react';
import { Box, CardMedia, CircularProgress, ClickAwayListener, Container, Grid, Link, Tab, Tabs, Tooltip, 
  TooltipProps, Typography, styled, tooltipClasses } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  getGridDateOperators,
  gridClasses,
} from '@mui/x-data-grid';
import { CometChat } from '@cometchat/chat-sdk-javascript';
import COLORS from 'theme/colors';
import { useNavigate } from 'react-router';
import { ROUTE } from 'route/RouteEnums';
import { getPatientsList, syncProfile } from 'store/slices/adminSlice';
import {getConversationGroupDetails, joinConversation} from 'store/slices/secureMessageSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { toast } from 'react-toastify';
import { CustomToolbar } from 'utils/functions';
import chat from 'assets/images/chat.svg';
import history from 'assets/images/history.svg';
import sync from 'assets/images/sync.svg';
import online from 'assets/images/online.svg';
dayjs.extend(utc);
function AdminDashboard() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any[] | null>();
  const [groupDetails, setGroupDetails] = useState<any>(null);
  const storeData = useAppSelector((state) => state.admin);
  const [open, setOpen] = React.useState<any>();
  const [filteredRows, setFilteredRows] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [checkedInCount, setCheckedInCount] = useState(0);
  const [todayAppointmentCount, setTodayAppointmentCount] = useState(0);
  const [allPatientCount, setAllPatientCount] = useState(0);
  
  const handleTooltipClose = () => {
    setOpen(null);
  };

  const StyledTooltip = styled(({ className, color, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltipArrow}`]: {
      backgroundColor: '#fff!important',
      boxShadow: theme.shadows[1]
    },
    [`& .${tooltipClasses.arrow}`]: {
      '&:before': {
        border: '1px solid #fff',
        color:'#fff',              
      },      
    },
    [`& .${tooltipClasses.tooltip}`]:{
      border: '1px solid #fff',
      padding:'12px',
      borderRadius:'8px'
    }
  }));

  const getAppointmentTime = (adate:any) => {
    const date = new Date(adate);
    let hours:any = date.getUTCHours();
    let minutes:any = date.getUTCMinutes();    
    const ampm = hours >= 12 ? 'pm' : 'am';
  
    hours %= 12;
    hours = hours || 12;   
    hours = hours < 10 ? `0${hours}` : hours; 
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    
    const strTime = `${hours}:${minutes} ${ampm}`;
  
    return strTime;
  };
  
  const handleHistory=(e:any,id:any)=>{
    e.stopPropagation();
    navigate(`${ROUTE.ADMIN_APPOINTMENT_DETAILS}/${id}`);
  };

  const handleSync=(e:any, id:any)=>{
    e.stopPropagation();
    dispatch(syncProfile({ id }))
      .unwrap()
      .then((res: any) => {
        if (res.status === 200) {
          getPatients();
        }
      });
  };

  const handleTooltipOpen=(e:any, id:number)=>{
    e.stopPropagation();
    setOpen(id);
    dispatch(getConversationGroupDetails(id))
      .unwrap()
      .then((response:any) =>{
        if(response?.status === 200){
          setGroupDetails(response.data);
        }
      });
  };

  const handleJoinConversation = (e: any, g:any) => {
    e.stopPropagation();
    dispatch(joinConversation(g.groupId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          navigate(`${ROUTE.ADMIN_MESSAGES}`);
        }
      });
  };

  const getToolTipTitle=(params:any)=>{
    const status = params.row.migrationStatus !== null ? params.row.migrationStatus : '';
    return(
      <>
        {params.row.patientName + ' ' + status}
      </>
    );
  };

  const columns: GridColDef[] = [
    {
      field:'action',
      headerName:'Action',
      filterable:false,
      sortable:false,  
      renderCell: (params:any) => {
        return (
          <>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <StyledTooltip 
                arrow             
                onClose={handleTooltipClose}
                onMouseLeave={handleTooltipClose}
                open={open === params.row.patientId}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                  <span style={{height:'150px'}}>
                    
                    {groupDetails === null ? 
                      <CircularProgress/> :
                      <>
                        {groupDetails?.length !==0 ?
                          <>
                            <Grid container 
                              spacing={1}
                              direction='row'
                              alignItems='center'
                              justifyContent='space-between'                          
                            >
                              <Grid item xs={11} style={{cursor:'default'}}>
                                <Typography sx={{ fontSize: '14px', fontWeight: 700, color:'#1C1F24', mb:'16px' }}>
                    Select Type to Start
                                </Typography> 
                              </Grid>
                              <Grid item xs={1} >
                                <Typography  sx={{ fontSize: '14px', fontWeight: 700, color:'#1C1F24', mb:'16px' }}
                                  onClick={(e: any) => { e.stopPropagation(); handleTooltipClose(); }}> X </Typography>
                              </Grid>
                            </Grid>
                            <Grid container>                      
                              {groupDetails?.map((g:any) => {
                                return(
                                  <>
                                    <Grid item xs={9}>
                                      <Typography 
                                        sx={{ color: '#3467FF', fontSize: '14px', 
                                          fontWeight: 500, mb: '16px', cursor: 'pointer'
                                        }} 
                                        onClick={(e:any) => { handleJoinConversation(e,g); }}
                                      >
                                        {g.groupName}
                                      </Typography>                         
                                    </Grid>
                                    <Grid item xs={3} onClick={(e:any) => { handleJoinConversation(e,g); }}>
                                      {g.activeConversation ? 
                                        <CardMedia src={online} component='img' 
                                          sx={{ width: '10px', height: '10px', mt: '4px', ml:'20px' }}/> 
                                        : ''}
                                    </Grid>
                                  </>
                                );
                              })
                              }
                            </Grid>
                          </> : 
                          <Grid container 
                            spacing={1}
                            direction='row'
                            alignItems='center'
                            justifyContent='space-between'                          
                          >
                            <Grid item xs={11} style={{cursor:'default'}}>
                              <Typography sx={{ fontSize: '14px', fontWeight: 700, color:'#1C1F24', mb:'16px' }}>
                                No groups
                              </Typography> 
                            </Grid>
                            <Grid item xs={1} >
                              <Typography  sx={{ fontSize: '14px', fontWeight: 700, color:'#1C1F24', mb:'16px' }}
                                onClick={(e: any) => { e.stopPropagation(); handleTooltipClose(); }}> X </Typography>
                            </Grid>
                            <Typography sx={{ fontSize: '14px', color:'#1C1F24', mb:'16px' }}>
                               There are no groups available.
                            </Typography>
                          </Grid>
                        }
                      </>
                    }
                  </span>          
                } 
                placement='right-end'
                sx={{paddingLeft:'50px'}}
                PopperProps={{
                  disablePortal: true,
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [20, -60],
                      },
                    },
                  ],
                }}
              >
                <CardMedia src={chat} component='img' sx={{ width: '24px', height: 'auto' }}
                  onClick={(e:any) => {handleTooltipOpen(e, params?.row?.patientId);}}
                />
              </StyledTooltip>
            </ClickAwayListener>
            <Link onClick ={(e:any) => handleHistory(e,params?.row?.patientId)}>
              <CardMedia src={history} component='img' sx={{ width: '24px', height: 'auto', ml: '12px' }} /> 
            </Link>
            {params?.row?.triggerSync === 'RETRY_LIMIT_EXCEEDED' ?
              <Link onClick={(e: any) => handleSync(e, params?.row?.patientId)}>
                <CardMedia src={sync} component='img' sx={{ width: '24px', height: 'auto', ml: '12px' }} />
              </Link> : null}

          </>
        );
      }
    },
    {
      field: 'patientName',
      headerName: 'Patient Name',
      width: 200,
      renderCell:(params:any) =>{
        return ( <>           
                  
          <span>
            <Tooltip title={getToolTipTitle(params) } 
              arrow placement='bottom-start' enterTouchDelay={0}>
              <span>
                {params.row.patientName}
                <span style={{
                  color: '#1570EF',
                  marginLeft: '5px',
                  fontSize: '11px',
                  fontWeight: 700,
                  lineHeight: '24px',
                }}>
                  {params.row.migrationStatus}
                </span> 
              </span>
            </Tooltip>
          </span>               
          
          {params.row.migrationStatus === null && params.row.type === 'NEW' ?
            <span style={{
              color: '#1570EF',
              marginLeft: '5px',
              fontSize: '11px',
              fontWeight: 700,
              lineHeight: '24px',
            }}>New</span> 
            : ''} 
        </>);
      }
    },
    {
      field: 'contactNumber',
      headerName: 'Contact Number',
      width:150
    },
    {
      field: 'registrationDate',
      headerName: 'Registration',
      description: 'Registration Date',      
      width:130,
      type:'date',
      filterOperators: getGridDateOperators().filter((operator) => operator.value === 'isNotEmpty' || 
      operator.value === 'isEmpty' || operator.value === 'onOrBefore' || operator.value === 'onOrAfter' || 
      operator.value === 'after' || operator.value === 'not' || operator.value === 'is'),
      valueFormatter: (params:any) => dayjs(params?.row?.registrationDate).utc().format('MM/DD/YYYY'),
      valueGetter:(params:any) => dayjs(params?.row?.registrationDate).utc().format('MM/DD/YYYY'),
      renderCell: (params: any) => {
        return params?.row?.registrationDate ?  
          <> {dayjs(params?.row?.registrationDate).utc().format('MM/DD/YYYY').substring(0,10)}
          </>: '';
      },
    },
    {
      field: 'profileCompletionDate',
      headerName: 'Profile',
      description: 'Profile Completion Date',      
      width:130,
      type:'date',
      filterOperators: getGridDateOperators().filter((operator) => operator.value === 'isNotEmpty' || 
      operator.value === 'isEmpty' || operator.value === 'onOrBefore' || operator.value === 'onOrAfter' || 
      operator.value === 'after' || operator.value === 'not' || operator.value === 'is'),
      valueFormatter: (params:any) => dayjs(params?.row?.profileCompletionDate).utc().format('MM/DD/YYYY'),
      valueGetter:(params:any) => dayjs(params?.row?.profileCompletionDate).utc().format('MM/DD/YYYY'),
      renderCell: (params: any) => {
        return params?.row?.profileCompletionDate ?  
          <> {dayjs(params?.row?.profileCompletionDate).utc().format('MM/DD/YYYY').substring(0,10)}
          </>: '';
      },
    },
    {
      field: 'patientId',
      headerName: 'Status',
      description: 'Appointment Status',
      width:120,
      valueFormatter:(params:any) => params?.row?.appointmentStatus,
      valueGetter: (params:any) => params?.row?.appointmentStatus,
      renderCell:(params:any) =>{  
        return(
          <>         
            <StyledTooltip 
              arrow
              enterTouchDelay={0}
              enterDelay={0}
              title={
                <React.Fragment>
                  <Typography sx={{fontSize:'18px', color:'#344054', fontFamily:'Inter',
                    fontWeight: 700, lineHeight: '18px', marginBottom:'8px'}}>
                     Latest Appointment</Typography> 
                  <Typography sx={{fontSize:'12px', color:'#667085', fontWeight:500,
                    fontFamily:'Inter', lineHeight:'18px', marginTop: '10px'}}>Provider Name</Typography>
                  <Typography sx={{fontSize:'14px', color:'#344054', fontFamily:'Inter',
                    fontWeight: 600, lineHeight: '18px', marginBottom:'8px', textTransform: 'capitalize'}}>
                    {params?.row?.providerName}
                  </Typography> 
                  <Typography sx={{fontSize:'12px', color:'#667085', fontWeight:500,
                    fontFamily:'Inter', lineHeight:'18px', marginTop: '10px'}}>Appointment Type</Typography>
                  <Typography sx={{fontSize:'14px', color:'#344054', fontFamily:'Inter',
                    fontWeight: 600, lineHeight: '18px', marginBottom:'8px'}}>
                    {params?.row?.appointmentType}
                  </Typography>
                  <Typography sx={{fontSize:'12px', color:'#667085', fontWeight:500,
                    fontFamily:'Inter', lineHeight:'18px', marginTop: '10px'}}>Location</Typography>
                  <Typography sx={{fontSize:'14px', color:'#344054', fontFamily:'Inter',
                    fontWeight: 600, lineHeight: '18px', marginBottom:'8px'}}>
                    {params?.row?.officeAddress}
                  </Typography>              
                </React.Fragment>
              } 
              placement='right-end'
              sx={{padding:'12px'}}
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [20, -60],
                    },
                  },
                ],
              }}
            >
              <Typography style={{textDecoration:'underline'}} 
                color= {getAppointmentStatusColor(params?.row?.appointmentStatus)} >
                {params?.row?.appointmentStatus ? params?.row?.appointmentStatus : ''}
              </Typography>
            </StyledTooltip>
          </>
        );
      },
      
    },
    {
      field: 'upcomingAppointment',
      headerName: 'Date',
      description: 'Appointment Date',      
      width:130,
      type:'date',
      filterOperators: getGridDateOperators().filter((operator) => operator.value === 'isNotEmpty' || 
      operator.value === 'isEmpty' || operator.value === 'onOrBefore' || operator.value === 'onOrAfter' || 
      operator.value === 'after' || operator.value === 'not' || operator.value === 'is'),
      valueFormatter: (params:any) => dayjs(params?.row?.upcomingAppointmentDate).utc().format('MM/DD/YYYY'),
      valueGetter:(params:any) => dayjs(params?.row?.upcomingAppointmentDate).utc().format('MM/DD/YYYY'),
      renderCell: (params: any) => {
        return params?.row?.upcomingAppointmentDate ?  
          <> {dayjs(params?.row?.upcomingAppointmentDate).utc().format('MM/DD/YYYY').substring(0,10)}
            {' - '} {getAppointmentTime(params?.row?.upcomingAppointmentDate)}
          </>: 'None Scheduled';
      },
    },
    {
      field: 'mcatScore',
      headerName: 'MCAT-i Score',
      type:'number',
      valueFormatter: (params:any) => parseInt(params?.row?.mcatIscore),
      valueGetter:(params:any) => parseInt(params?.row?.mcatIscore),
      renderCell: (params: any) => {
        return params.row.mcatIscore > 0 ?<Typography color={getMCatScoreColor(params.row.mcatIscore)} 
          sx={{fontSize:'14px', fontWeight:'600'}}>{params.row.mcatIscore}</Typography>: '-';
      },
    },

    {
      field: 'wellbeingScore',
      headerName: 'Wellness Score',
      type:'number',
      valueFormatter: (params:any) => parseInt(params?.row?.wellnessScore),
      valueGetter:(params:any) => parseInt(params?.row?.wellnessScore),
      renderCell: (params: any) => {
        return params.row.wellnessScore >0 ? <Typography color={getWellbeingScoreColor(params.row.wellnessScore)} 
          sx={{fontSize:'14px', fontWeight:'600'}}>{params.row.wellnessScore}</Typography>: '-';
      },
    },
    {
      field: 'wellbeing',
      headerName: 'Wellness-Q',
      width:130,
      type:'date',
      filterOperators: getGridDateOperators().filter((operator) => operator.value === 'isNotEmpty' || 
      operator.value === 'isEmpty' || operator.value === 'onOrBefore' || operator.value === 'onOrAfter' || 
      operator.value === 'after' || operator.value === 'not' || operator.value === 'is'),
      valueFormatter: (params:any) => dayjs(params.value).format('MM/DD/YYYY'),
      valueGetter:(params:any) => dayjs(params.value).format('MM/DD/YYYY'),
      renderCell: (params: any) => {
        if( dayjs(params.value).format('MM/DD/YYYY') === 'Invalid Date'){
          return '-';
        }
        else {
          return dayjs(params.value).format('MM/DD/YYYY');
        }
      },
    },
    {
      field: 'mcat',
      headerName: 'M-CAT',
      width:130,
      type:'date',
      filterOperators: getGridDateOperators().filter((operator) => operator.value === 'isNotEmpty' || 
      operator.value === 'isEmpty' || operator.value === 'onOrBefore' || operator.value === 'onOrAfter' || 
      operator.value === 'after' || operator.value === 'not' || operator.value === 'is'),
      valueFormatter: (params:any) => dayjs(params.value).format('MM/DD/YYYY'),
      valueGetter:(params:any) => dayjs(params.value).format('MM/DD/YYYY'),
      renderCell: (params: any) => {
        if( dayjs(params.value).format('MM/DD/YYYY') === 'Invalid Date'){
          return '-';
        }
        else {
          return dayjs(params.value).format('MM/DD/YYYY');
        }
      },
    },
    {
      field: 'intakePaperwork',
      headerName: 'Consents',
      width:130,
      type:'date',
      filterOperators: getGridDateOperators().filter((operator) => operator.value === 'isNotEmpty' || 
      operator.value === 'isEmpty' || operator.value === 'onOrBefore' || operator.value === 'onOrAfter' || 
      operator.value === 'after' || operator.value === 'not' || operator.value === 'is'),
      valueFormatter: (params:any) => dayjs(params.value).format('MM/DD/YYYY'),
      valueGetter:(params:any) => dayjs(params.value).format('MM/DD/YYYY'),
      renderCell: (params: any) => {
        if( dayjs(params.value).format('MM/DD/YYYY') === 'Invalid Date'){
          return '-';
        }
        else {
          return dayjs(params.value).format('MM/DD/YYYY');
        }
      },
    },
    {
      field: 'mfsh',
      headerName: 'MFSH',
      width:130,
      type: 'date',
      filterOperators: getGridDateOperators().filter((operator) => operator.value === 'isNotEmpty' || 
      operator.value === 'isEmpty' || operator.value === 'onOrBefore' || operator.value === 'onOrAfter' || 
      operator.value === 'after' || operator.value === 'not' || operator.value === 'is'),
      valueFormatter: (params:any) => dayjs(params?.value).format('MM/DD/YYYY'),
      valueGetter:(params:any) =>  dayjs(params?.value).format('MM/DD/YYYY'),
      renderCell: (params: any) => {
        if( dayjs(params.value).format('MM/DD/YYYY') === 'Invalid Date'){
          return '-';
        }
        else {
          return dayjs(params.value).format('MM/DD/YYYY');
        }
      },
    },
    
  ];

  const getMCatScoreColor = (mcatScore: number) => {
    if( mcatScore < 50) { return '#F04438';}
    else if( mcatScore > 50 && mcatScore <= 100) { return '#FF4405';}
    else if( mcatScore > 100 && mcatScore <= 225) {return '#EF6820';}
    else if( mcatScore > 225 && mcatScore <= 325) {return '#F79009';}
    else if( mcatScore > 325 && mcatScore <= 400) {return '#EAAA08';}
    else if( mcatScore > 400 && mcatScore <= 450) {return '#66C61C';}
    else if( mcatScore > 450 && mcatScore <= 500) {return '#12B76A';}       
  };

  const getWellbeingScoreColor = (wellbeingScore: number) => {
    if( wellbeingScore < 100) { return '#F04438';}
    else if( wellbeingScore > 100 && wellbeingScore <= 200) {return '#FF4405';}
    else if( wellbeingScore > 200 && wellbeingScore <= 450) {return '#EF6820';}
    else if( wellbeingScore > 450 && wellbeingScore <= 650) {return '#F79009';}
    else if( wellbeingScore > 650 && wellbeingScore <= 800) {return '#EAAA08';}
    else if( wellbeingScore > 800 && wellbeingScore <= 900) {return '#66C61C';}
    else if( wellbeingScore > 900 && wellbeingScore <= 1000) {return '#12B76A';}       
  };

  const getAppointmentStatusColor = (appointmentStatus: any) => {
    if(appointmentStatus === 'Confirmed') {return '#1570EF';}
    else if(appointmentStatus === 'Complete') {return '#027A48';}
    else if(appointmentStatus === 'Cancelled') {return '#912018';}
    else if(appointmentStatus === 'Late Cancel') {return '#912018';}
    else if(appointmentStatus === 'Bumped') {return '#912018';}
    else if(appointmentStatus === 'No-show') {return '#912018';}
    else if(appointmentStatus === 'Scheduled') {return '#1570EF';}
    return '#1570EF';
  };


  const rowSelect = (params: any) => {
    navigate(`${ROUTE.ADMIN_PATIENT_INFO}/${params.row.patientId}`, { state: { data: params.row } });
  };

  const getPatients = async () => {
    const listType = selectedTab === 0 ? 'today_physical_checkin' : selectedTab === 1 ? 'today_physical_virtual' :'all';

    await dispatch(getPatientsList(listType))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          const sortedData = response?.data !=='' && 
          response?.data?.patientList?.sort((a: any, b: any) => b.patientId - a.patientId);
          setData(sortedData);
          setCheckedInCount(response?.data?.checkinRecords < 10 ? `0${response?.data?.checkinRecords}` :
            response?.data?.checkinRecords);
          setTodayAppointmentCount(response?.data?.virtualAndPhysicalRecords < 10 ? 
            `0${response?.data?.virtualAndPhysicalRecords}` : response?.data?.virtualAndPhysicalRecords);
          setAllPatientCount(response?.data?.allRecords);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    getPatients();
    window.onpopstate = () => {
      navigate(ROUTE.ADMIN_DASHBOARD);
    };
    //eslint-disable-next-line
  }, [selectedTab]);

  useEffect(() =>{
    CometChat.addMessageListener(
      'chatMessage',
      new CometChat.MessageListener({
        onTextMessageReceived: (textMessage: CometChat.TextMessage) => {
          toast('You have new message',{
            type:'info',
            icon:true
          });
        },
        onMediaMessageReceived: (mediaMessage: CometChat.MediaMessage) => {
          toast('You have new message',{
            type:'info',
            icon:true
          });
        }       
      })
    );
  },[]);

  const handleOnStateChnage=(e:any)=>{
    setFilteredRows(e.rowsMeta.positions.length);
  };

  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTab(newValue);
  };

  return (
    <Container maxWidth='lg'>
      <Grid item xs={12}>
        <Typography
          variant='h5'
          position={'relative'}
          width={'fit-content'}
          sx={{ paddingTop: 6, textTransform: 'capitalize' }}
        >
          Patient List
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ backgroundColor: COLORS.White }}>
        <Grid container sx={{background:'#FFFBF1'}}>
          <Grid item xs={6} mb='10px'>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              sx={{
                background: '#F9FAFB', 
                borderRadius: '23px',
                border:'1px solid #F2F4F7', 
                marginTop: '10px',
                padding:'6px 14px',
                '.MuiTab-root.Mui-selected': {
                  background: '#9F84FF',
                  color: '#fff',
                  borderRadius: '23px',
                  fontWeight: 500,
                }
              }}
              TabIndicatorProps={{
                style: {
                  background: 'transparent',
                }
              }}
              centered
            >
              <Tab
                label={<Grid>
                      Checked In Today 
                  <span style={{background: selectedTab === 0 ? '#644EB5' : '#F2F4F7',
                    borderRadius:'23px', 
                    padding:'5px 7px',
                    marginLeft:'5px'}}>
                    {checkedInCount}</span>
                </Grid>}/>
                  
              <Tab
                label={<Grid>
                      Appointments Today  <span style={{background:selectedTab === 1 ? '#644EB5' : '#F2F4F7', 
                    borderRadius:'23px', 
                    padding:'5px 7px'}}>
                    {todayAppointmentCount}</span>
                </Grid>}/>
                  
              <Tab
                label={<Grid>
                      All Patients <span style={{background:selectedTab === 2 ? '#644EB5' : '#F2F4F7', 
                    borderRadius:'23px', 
                    padding:'5px 7px'}}>
                    {allPatientCount}</span>
                </Grid>}/>
                  
            </Tabs>
          </Grid>    
        </Grid>    
        <DataGrid
          autoHeight
          loading={loading || storeData.loaders.isUserFetched}
          rows={data ? data : []}          
          getRowId={(row: any) => row.patientId}
          columns={columns}
          onRowClick={rowSelect}
          onStateChange={handleOnStateChnage}
          rowHeight={filteredRows !== 0 && filteredRows < 3 ? 150 : 50}
          slots={{
            toolbar: CustomToolbar,
          }}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }}
          pageSizeOptions={[10, 25, 50]}
          rowSpacingType='margin'
          getRowClassName={(params: any) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
          style={{ cursor: 'pointer' }}
          sx={{
            border: 'none',
            backgroundColor: COLORS.LightYellow,
            fontSize: 14,
            fontWeight: 400,
            '.MuiDataGrid-toolbarContainer': {
              background: '#FFFBF1',
            },
            '.MuiDataGrid-columnHeaders': {
              marginBottom: 2,
              boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.16)',
            },
            '.MuiDataGrid-columnHeadersInner': {
              background: COLORS.White,
            },
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: 600,
            },
            [`& .${gridClasses.row}.even`]: {
              backgroundColor: COLORS.White,
              '&:hover, &.Mui-hovered': {
                backgroundColor: COLORS.LightBlue,  
              },
            },
            [`& .${gridClasses.row}.odd`]: {
              backgroundColor: COLORS.LightGray,
              '&:hover, &.Mui-hovered': {
                backgroundColor: COLORS.LightBlue,
              },
            },
            '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
              outline: 'none !important',
            },
            '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 400,
              fontSize: '16px',
              color: '#000000',
              lineHeight: '24px'
            },
          }}
        />        
      </Grid>
    </Container>
  );
}

export default AdminDashboard;
