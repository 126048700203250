/* eslint-disable indent */
import { Dialog, DialogContent, Box, CardMedia, Typography, Button, Stack } from '@mui/material';
import useIosInstallPrompt from './useISOInstallPrompt';
import useWebInstallPrompt from './useWebInstallPrompt';
import logo from 'assets/images/logo.svg';

export const InstallPWA = () => {
    const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
    const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] = useWebInstallPrompt();

    if (!iosInstallPrompt && !webInstallPrompt) {
        return (
            <>
                <Dialog
                    open={true}
                    onClose={handleIOSInstallDeclined}
                    maxWidth='xs'
                    aria-describedby='alert-dialog-description'
                    scroll='paper'
                >
                    <DialogContent>
                        <Box className="d-flex justify-content-around">
                            <CardMedia
                                component="img"
                                image={logo}
                                sx={{
                                    width: 'auto',
                                    height: { xs: '45px', md: '60px' },
                                    margin: '0 auto 20px auto',
                                }}
                                alt="logo"
                            />
                            <Typography variant='h3' mb={3} align={'center'}>
                                MindWeal app is already installed on your device.
                            </Typography>

                            <Stack justifyContent={'center'} flexDirection={'row'}>
                                <Button onClick={handleWebInstallDeclined}>Close</Button>
                            </Stack>
                        </Box>
                    </DialogContent>
                </Dialog>
            </>
        );
    }

    return (
        <Dialog
            open={true}
            onClose={handleIOSInstallDeclined}
            maxWidth='xs'
            aria-describedby='alert-dialog-description'
            scroll='paper'
        >
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {iosInstallPrompt && (
                        <>
                            <Box className="text-center">
                                Tap
                                <img
                                    src="content/images/Navigation_Action_2x.png"
                                    style={{ margin: 'auto 8px 8px' }}
                                    className=""
                                    alt="Add to homescreen"
                                    width="20"
                                />
                                then &quot;Add to Home Screen&quot;
                            </Box>
                            <div className="d-flex justify-content-center">
                                <Button onClick={handleIOSInstallDeclined}>Close</Button>
                            </div>
                        </>
                    )}

                    {webInstallPrompt && (
                        <Box className="d-flex justify-content-around">
                            <CardMedia
                                component="img"
                                image={logo}
                                sx={{
                                    width: 'auto',
                                    height: { xs: '45px', md: '60px' },
                                    margin: '0 auto 20px auto',
                                }}

                                alt="image"
                            />
                            <Typography variant='h3' mb={3} align={'center'}>
                                Installing MindWeal app on your device.
                            </Typography>

                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                <Button color="primary" onClick={handleWebInstallAccepted}>
                                    Yes! Go ahead
                                </Button>
                                <Button variant='outlined' onClick={handleWebInstallDeclined}>Cancel</Button>
                            </Stack>
                        </Box>
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    );
};