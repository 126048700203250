export const BASE_URL = 'https://'+window.location.hostname+'/api';

export const API_URL = {
  CONDITION_AND_POLICY: 'https://www.mindweal.com/wp-json/wp/v2/pages',

  SIGN_UP: `${BASE_URL}/auth/1.0/signup`,
  MIGRATE:`${BASE_URL}/auth/1.0/migration/signup`,
  ACCOUNT_CONFIRMATION: `${BASE_URL}/auth/1.0/activate-account`,
  RESEND_EMAIL_VERIFICATION_LINK: `${BASE_URL}/auth/1.0/resend-verification`,
  SEND_RESET_PASSWORD_EMAIL: `${BASE_URL}/auth/1.0/send-reset-password-link`,
  GET_EMAIL_API: `${BASE_URL}/auth/1.0/user-email`,
  RESET_PASSWORD: `${BASE_URL}/auth/1.0/reset-password`,
  SEND_QUERY: '/auth/1.0/registration-query',
  VALIDATE_TOKEN: '/auth/1.0/validate-token',
  SIGN_IN: '/auth/1.0/signin',
  VALIDATE_CAPTCHA: '/auth/1.0/verify',
  REFRESH_TOKEN:'/auth/1.0/refreshtoken',

  // Dashboard api endpoints
  GET_USER_DETAILS: `${BASE_URL}/user-mgmt/users/1.0/`,
  GET_REGISTRATION_DETAILS: `${BASE_URL}/users/1.0`,
  PROFILE_STATUS: `${BASE_URL}/patient-mgmt/profile/1.0`,

  //  Appointment api end points
  SCHEDULE_APPOINTMENT: `${BASE_URL}/ehr-service/appointmentRequest`,
  APPOINTMENT_CONFIRMATION_DETAILS: `${BASE_URL}/ehr-service/appointment`,
  APPOINTMENT_HISROTY: `${BASE_URL}/ehr-service/appointment/historyDetails/`,

  DOCUMENT_LIST: `${BASE_URL}/documentation-mgmt/document/list`,
  DOWNLOAD_DOCUMENT: `${BASE_URL}/documentation-mgmt/document/download`,
  //eslint-disable-next-line
  PATIENTS_LIST: `${BASE_URL}/patient-mgmt/patientDetails/1.0/admin/patients`,
  PATIENT_EHR_DETAILS: `${BASE_URL}/patient-mgmt/patientDetails/1.0/admin`,
  DR_CHRONO_CHART_DETAILS: `${BASE_URL}/ehr-sync-service/ehrMapping`,
  PAYMENT_STATUS: `${BASE_URL}/ehr-service/payment/status`,
  UPCOMING_APPOINTMENT_DETAILS: `${BASE_URL}/ehr-service/appointment`,
  POST_MEETING_DETAILS: `${BASE_URL}/ehr-service/meeting/1.0`,
  MCAT_I_SCORE_DETAILS: `${BASE_URL}/assessment-service/mcatIScore`,
  MCAT_I_SCORE_APPOINTMENT_DETAILS: `${BASE_URL}/ehr-service/appointment/list/`,
  POST_MEETING_SUMMARY: `${BASE_URL}/ehr-service/appointment/summery`,

  SEND_DOCUMENT_REMINDER: `${BASE_URL}/documentation-mgmt/document/sendReminder`,
  SEND_ASSESSNEBT_REMINDER: `${BASE_URL}/assessment-service`,

  SAVE_MCAT_ANSWERS: `${BASE_URL}/assessment-service/mcat/1.0`,
  GET_MCAT_STATUS: `${BASE_URL}/assessment-service/mcat/1.0/status`,
  SAVE_WELLBEING_ANSWERS: `${BASE_URL}/assessment-service/wellbeing/1.0`,
  GET_WELLBEING_STATUS: `${BASE_URL}/assessment-service/wellbeing/1.0/status`,
  GET_MFSH_STATUS: `${BASE_URL}/assessment-service/mfsh/1.0/status`,
  GET_WELLBEING_TRACKER:`${BASE_URL}/assessment-service/wellbeing/1.0/history`,
  GET_MFSH_LINK: `${BASE_URL}/assessment-service/mfsh/1.0/jotform-url`,
  GET_MCAT_SCORE: `${BASE_URL}/assessment-service/mcatIScore`,
  GET_MCAT_TRACKER:`${BASE_URL}/assessment-service/mcatIScore/history`,
  GET_WELLBEING_SCORE: `${BASE_URL}/assessment-service/wellbeing/1.0/score`,
  // Profile api endpoints
  GET_PATIENT: '/patient-mgmt/patient/1.0',
  PATIENT_INFORMATION: '/patient-mgmt/patient/1.0',
  GUARDIAN_DETAILS: '/patient-mgmt/guardian/1.0/',
  PROVIDER_DETAILS: '/patient-mgmt/provider/1.0/',
  PHARMACY_DETAILS: `${BASE_URL}/patient-mgmt/pharmacy/1.0/`,
  INSURANCE_DETAILS: `${BASE_URL}/patient-mgmt/payment/1.0/`,
  FILE_UPLOAD: `${BASE_URL}/patient-mgmt/file/1.0/`,
  PROFILE_IMAGE: `${BASE_URL}/patient-mgmt/patient/1.0/image`,
  GUARDIAN_IMAGE: `${BASE_URL}/patient-mgmt/guardian/1.0/image`,

  PATIENT_DETAILS_FOR_ADMIN: `${BASE_URL}/patient-mgmt/patient/1.0/admin`,
  USER_PERMISSIONS: `${BASE_URL}/user-mgmt/users/1.0/currentUser`,
  // Vitals api endpoints
  VITALS_DETAILS:`${BASE_URL}/patient-mgmt/vitals/1.0`,
  GET_CHARTID_CONVERSATION_STATUS: `${BASE_URL}/secure-messaging-service/conversation`,
  GET_CONVERSATION_STATUS:`${BASE_URL}/secure-messaging-service/conversation/status`,
  GET_CHARTID:`${BASE_URL}/ehr-sync-service/ehrMapping/user`,
  GET_VMA_NAMES:`${BASE_URL}/user-mgmt/staff/1.0/teams`,
  CREATE_TASK:`${BASE_URL}/secure-messaging-service/task/create`,
  GET_TASK_LIST:`${BASE_URL}/secure-messaging-service/task/assigned`,
  GET_PATIENT_TASK_LIST:`${BASE_URL}/secure-messaging-service/task/user`,
  SYNC_PROFILE:`${BASE_URL}/patient-mgmt/profile/1.0/trigger/sync`,
  GET_CITY_STATE:`${BASE_URL}/patient-mgmt/address/1.0/zipcode/`,
  GET_UNASSIGNED_CONVERSATION:`${BASE_URL}/secure-messaging-service/conversation/unassigned`,
  GET_ASSIGNED_CONVERSATION:`${BASE_URL}/secure-messaging-service/conversation/assigned`,
  GET_GROUP_DETAILS:`${BASE_URL}/secure-messaging-service/cometchat/user/groups`,
  ASSIGN_CONVERSATION_TO_SELF:`${BASE_URL}/secure-messaging-service/conversation/assign`,
  ASSIGN_CONVERSATION_TO_OTHER:`${BASE_URL}/secure-messaging-service/conversation/assign`,
  JOIN_CONVERSATION:`${BASE_URL}/secure-messaging-service/conversation/join`,
  GET_CONVERSATION_COUNT: `${BASE_URL}/secure-messaging-service/conversation/count`,
  GET_VMA_STATUS_COUNT:`${BASE_URL}/secure-messaging-service/cometchat/vma/status`,
  CHECK_IN_DETAILS:`${BASE_URL}/ehr-service/appointment/checkin`,
  VALIDATE_ZIP_CODE:`${BASE_URL}/auth/1.0/zipcode`,
  CREATE_PATIENT:`${BASE_URL}/ehr-service/appointment/createPatient`,
  GET_REPORT_TILES:`${BASE_URL}/assessment-service/mcat/1.0/provider/report`,
  GET_REPORT_DETAILS:`${BASE_URL}/assessment-service/mcat/1.0/provider/report`,
  SAVE_RATING:`${BASE_URL}/assessment-service/mcat/1.0/ratings`,  
  SAVE_SUGGESTIONS:`${BASE_URL}/assessment-service/mcat/1.0/comments`,
  UPDATE_PIN: `${BASE_URL}/user-mgmt/users/1.0/pin/change`,
  CHECK_PIN:`${BASE_URL}/user-mgmt/users/1.0/pin/verify`,
  GET_STRIPE_LINK:`${BASE_URL}/payment-service/card/manage`,
  PAY_AMOUNT:`${BASE_URL}/ehr-service/payment/generate`,  
  GET_TASK_COUNT :`${BASE_URL}/secure-messaging-service/task/count`
};
