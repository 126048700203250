import { apiInterceptor } from './axios';
import { API_URL } from 'shared/api/Constants';


export const saveMcatAnswersAPI = (data: any) => {
  return apiInterceptor
    .post(`${API_URL.SAVE_MCAT_ANSWERS}`, data)
    .then((response: any) => {
      return response;
    });
};

export const getMCATStatusAPI = () => {
  return apiInterceptor
    .get(`${API_URL.GET_MCAT_STATUS}`)
    .then((response: any) => {
      return response;
    });
};

export const getWellbeingStatusAPI = () => {
  return apiInterceptor
    .get(`${API_URL.GET_WELLBEING_STATUS}`)
    .then((response: any) => {
      return response;
    });
};

export const getMFSHStatusAPI = () => {
  return apiInterceptor
    .get(`${API_URL.GET_MFSH_STATUS}`)
    .then((response: any) => {
      return response;
    });
};

export const getWellbeingTrackerDetailsAPI = (data:any) => {
  return apiInterceptor
    .get(`${API_URL.GET_WELLBEING_TRACKER}/${data.period}`)
    .then((response: any) => {
      return response;
    });
};

export const getMCATTrackerDetailsAPI = (data:any) => {
  return apiInterceptor
    .get(`${API_URL.GET_MCAT_TRACKER}/${data.period}`)
    .then((response: any) => {
      return response;
    });
};

export const getMFSHLinkAPI = () => {
  return apiInterceptor
    .get(`${API_URL.GET_MFSH_LINK}`)
    .then((response: any) => {
      return response;
    });
};

export const saveWellbeingAnswersAPI = (data: any) => {
  return apiInterceptor
    .post(`${API_URL.SAVE_WELLBEING_ANSWERS}`, data)
    .then((response: any) => {
      return response;
    });
};

export const getMCATScoreAPI = () => {
  return apiInterceptor
    .get(`${API_URL.GET_MCAT_SCORE}`)
    .then((response: any) => {
      return response;
    });
};

export const getWellbeingScoreAPI = () => {
  return apiInterceptor
    .get(`${API_URL.GET_WELLBEING_SCORE}`)
    .then((response: any) => {
      return response;
    });
};

export const getMCATReportTilesAPI=()=>{
  return apiInterceptor
    .get(`${API_URL.GET_REPORT_TILES}`)
    .then((response: any) => {
      return response;
    });
};

export const getMCATReportDetailsAPI = (type: string) => {
  return apiInterceptor
    .get(`${API_URL.GET_REPORT_DETAILS}/${type}`)
    .then((response: any) => {
      return response;
    });
};

export const saveRatingsAPI = async (data: any) => {
  const response = await apiInterceptor
    .post(`${API_URL.SAVE_RATING}`, data);
  return response;
};

export const saveSuggestionsAPI = async (data:any) => {
  const response = await apiInterceptor
    .post(`${API_URL.SAVE_SUGGESTIONS}`, data);
  return response;
};

